<template>
  <div>
    <div class="dev-main">
      <div class="checks">
        <h4>Checks</h4>
        <p>Tests, result in console</p>

        <div class="buttons">
          <button @click="checkUserChatrooms">checkUserChatrooms</button>
          <button @click="checkCompanyChatrooms">checkCompanyChatrooms</button>
          <button @click="checkForDeletedPostsOnCompanies">
            checkForDeletedPostsOnCompanies
          </button>
          <button @click="checkForAllowCredits">checkForAllowCredits</button>
          <button @click="testCompanyFields">testCompanyFields</button>
          <button @click="testUserFields">testUserFields</button>
          <button @click="findUsersWhoChangedEmail">
            findUsersWhoChangedEmail
          </button>
          <!-- <button @click="test">test</button>
          <button @click="test2">test2</button>
          <button @click="test3">test3</button>
          <button @click="test4">test4</button> -->
          <button @click="test5">test5</button>
          <button @click="test6">test6</button>
          <button @click="test7">test7</button>
          <button @click="test8">test8</button>
          <button @click="test9">test9</button>
          <button @click="test10">test10</button>
          <button @click="test11">test11</button>
          <button @click="test12">test12</button>
          <button @click="test13">test13</button>
          <button @click="test14">test14</button>
          <button @click="test15">test15</button>
          <button @click="test16">test16</button>
          <button @click="test17">test17</button>
          <button @click="test18">test18</button>

          <button @click="checkCartBug">checkCartBug</button>
          <button @click="temp1">temp1</button>
          <button @click="temp2">temp2</button>
          <button @click="temp2">temp2</button>
          <button @click="test">test</button>
          <button @click="test111">test111</button>
        </div>
      </div>

      <div class="navigation">
        <h4>Navigation</h4>
        <p>Switch companies in dev.</p>

        <div class="buttons">
          <button
            v-if="appBrand !== 'fiskerikajen'"
            @click="changeBrand('fiskerikajen')"
          >
            Go to Fiskerikajen
          </button>
          <button
            v-if="appBrand !== 'kvalitetsfisk'"
            @click="changeBrand('kvalitetsfisk')"
          >
            Go to Kvalitetsfisk
          </button>
          <button
            v-if="appBrand !== 'fiskidag'"
            @click="changeBrand('fiskidag')"
          >
            Go to Fisk Idag
          </button>
          <button v-if="appBrand !== 'fsg'" @click="changeBrand('fsg')">
            Go to FSG
          </button>
        </div>
      </div>

      <div class="simple-actions">
        <h4>Simple actions</h4>
        <p>Alters data.</p>

        <div class="buttons">
          <button @click="adminDev">adminDev</button>

          <button @click="clearOldUserChatRooms">clearOldUserChatRooms</button>
          <button @click="clearOldCompanyChatRooms">
            clearOldCompanyChatRooms
          </button>

          <button @click="generateMissingChatRooms">
            generateMissingChatRooms
          </button>

          <button @click="addNamesToPersonalChatRooms">
            addNamesToPersonalChatRooms
          </button>
          <button @click="addAccountOwnerToPersonalChatRooms">
            addAccountOwnerToPersonalChatRooms
          </button>
          <button @click="addNameAndAccountOwnerToCompanyChatroom">
            addNameAndAccountOwnerToCompanyChatroom
          </button>

          <button @click="adminSetUsersLastSignInManually">
            adminSetUsersLastSignInManually
          </button>

          <button @click="createExportableShortlists">
            createExportableShortlists
          </button>
          <button @click="adminSyncExportableShortlistsManually">
            adminSyncExportableShortlistsManually
          </button>
          <button @click="adminCreateLangFieldOnUsers">
            adminCreateLangFieldOnUsers
          </button>
          <button @click="adminCreateCartDocOnUsers">
            adminCreateCartDocOnUsers
          </button>

          <button @click="createAnalyticsForExistingUsers">
            createAnalyticsForExistingUsers
          </button>
          <button @click="initRecommendedProductsField">
            initRecommendedProductsField
          </button>

          <button @click="initRequiredCompanyFields">
            initRequiredCompanyFields
          </button>
          <button @click="convertProductCategoriesWithTranslations">
            convertProductCategoriesWithTranslations
          </button>
          <button @click="initRequiredTranslationFields">
            initRequiredTranslationFields
          </button>
          <button @click="createNotificationsDocOnUsers">
            createNotificationsDocOnUsers
          </button>
          <button @click="createNewAllChatRoomDoc">
            createNewAllChatRoomDoc
          </button>
          <button @click="createAdminUsersList">createAdminUsersList</button>
          <button @click="addSettingsDocToUser">addSettingsDocToUser</button>
          <button @click="addUpsellFieldToCompany">
            addUpsellFieldToCompany
          </button>
          <button @click="createAdminCompanyList">
            createAdminCompanyList
          </button>
          <button @click="addAccountOwnerToAnalyticsDocument">
            addAccountOwnerToAnalyticsDocument
          </button>
          <button @click="addPlacedOrderAndSignedInToAnalyticsDocuments">
            addPlacedOrderAndSignedInToAnalyticsDocuments
          </button>
          <button @click="addCompaniesToRemainingUsers">
            addCompaniesToRemainingUsers
          </button>
          <button @click="addHomeUpsellToAllCompanies">
            addHomeUpsellToAllCompanies
          </button>
          <button @click="addTypeToAllUpsellLists">
            addTypeToAllUpsellLists
          </button>
          <button @click="addSelectionFieldsToAllUpsellLists">
            addSelectionFieldsToAllUpsellLists
          </button>
          <button @click="addCreatedUpdatedToExistingShortlists">
            addCreatedUpdatedToExistingShortlists
          </button>

          <button @click="fixAccountOwnerOnPersonalChatRooms">
            fixAccountOwnerOnPersonalChatRooms
          </button>

          <button @click="addDaysToDeliveryCutOff">
            addDaysToDeliveryCutOff
          </button>

          <button @click="fixPushNotificationSettings">
            fixPushNotificationSettings
          </button>

          <button @click="addPopupsDocToAllUsers">
            addPopupsDocToAllUsers
          </button>
          <button @click="fixPinDeliver">fixPinDeliver</button>
          <button @click="removeDeletedShortlistsFromCompanies">
            removeDeletedShortlistsFromCompanies
          </button>
          <button @click="removeDeletedPostsFromCompanies">
            removeDeletedPostsFromCompanies
          </button>
          <button @click="addCurrencyToUsers">addCurrencyToUsers</button>
          <button @click="fixAnalyticsFirstWeekOfYear">
            fixAnalyticsFirstWeekOfYear
          </button>

          <button @click="kfSetTest1Group">kfSetTest1Group</button>
          <button @click="addSlugToAllExistingPosts">
            addSlugToAllExistingPosts
          </button>
          <button @click="addPublicToAllExistingPosts">
            addPublicToAllExistingPosts
          </button>
          <button @click="addPublicToAllExistingUpsellLists">
            addPublicToAllExistingUpsellLists
          </button>
          <button @click="setupWebPageMenus">setupWebPageMenus</button>
          <button @click="fixNewsSlugs">fixNewsSlugs</button>
          <button @click="addPostSlugToChatMessages">
            addPostSlugToChatMessages
          </button>
        </div>
      </div>

      <div class="actions">
        <h4>Actions</h4>
        <p>Should be run with extreme caution.</p>

        <div class="buttons">
          <button class="disabled" @click="clearUserCarts">
            clearUserCarts
          </button>
          <button class="disabled" @click="clearUserChatNotifications">
            clearUserChatNotifications
          </button>
          <button class="disabled" @click="addShortlistsFieldToCompanies">
            addShortlistsFieldToCompanies
          </button>
          <button class="disabled" @click="addProductsPriceFieldToCompanies">
            addProductsPriceFieldToCompanies
          </button>
          <button class="disabled" @click="addProductsStockFieldToCompanies">
            addProductsStockFieldToCompanies
          </button>
          <button class="disabled" @click="addPriceListFieldToCompanies">
            addPriceListFieldToCompanies
          </button>
          <button class="disabled" @click="migrateCompanyShortlist">
            migrateCompanyShortlist
          </button>
          <button
            class="disabled"
            @click="setInitialPriceListOnExistingCompanies"
          >
            setInitialPriceListOnExistingCompanies
          </button>
          <button @click="createPriceLists">createPriceLists</button>
          <button @click="createPriceListsNew">createPriceListsNew</button>
          <button @click="syncPriceListsNew">syncPriceListsNew</button>
          <button @click="setBaseDiscounts">setBaseDiscounts</button>
          <button @click="moveTaxonomies">moveTaxonomies</button>
          <button @click="adminSyncFreightExternalManually">
            adminSyncFreightExternalManually
          </button>
          <button @click="adminSyncFreightCompanyManually">
            adminSyncFreightCompanyManually
          </button>
          <button @click="adminSyncFreightChainManually">
            adminSyncFreightChainManually
          </button>
          <button class="disabled" @click="setupRecommendedGroupsFromBigQuery">
            setupRecommendedGroupsFromBigQuery
          </button>
          <button class="disabled" @click="createControlGroupRecommendations">
            createControlGroupRecommendations
          </button>
          <button @click="setupRecommendedProductsFromBigQuery">
            setupRecommendedProductsFromBigQuery
          </button>
          <button @click="clearAllAdminOrderNotifications">
            clearAllAdminOrderNotifications
          </button>
          <button @click="cloneWebPage">cloneWebPage</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { db } from "@/main.js";
import "firebase/firestore";
/* eslint-disable */
import firebase from "firebase/app";
import { triggerRef } from "vue";

// import data from "@/data.json";
// import strings from "@/strings.json";

// import deliveryData from "@/deliveryData.json";

// import abTestGroups from "@/AB_test_groups_311022.json";
import recommendedShortlists from "@/shortlists_18jan.json";

function slugify(text) {
  // Mapping Scandinavian characters to their Latin equivalents
  const map = {
    å: "a",
    ä: "a",
    ö: "o",
    ø: "o",
    æ: "ae",
    Å: "A",
    Ä: "A",
    Ö: "O",
    Ø: "O",
    Æ: "AE",
  };

  return text
    .toString() // Ensure the input is treated as a string
    .split("") // Split into an array to individually map Scandinavian characters
    .map((char) => map[char] || char) // Replace Scandinavian characters using the map, or leave the character unchanged
    .join("") // Rejoin the characters into a single string
    .toLowerCase() // Convert the entire string to lowercase for uniformity
    .trim() // Remove whitespace from both ends of the string
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^\w-]+/g, "") // Remove all non-word characters except hyphens
    .replace(/-+/g, "-"); // Replace multiple consecutive hyphens with a single hyphen
}

export default {
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapState({
      appSettings: (state) => state.settings.appSettings,
      userName: (state) => state.settings.user.name,
      userEmail: (state) => state.settings.user.email,
      appBrand: (state) => state.settings.appSettings.app,
      userList: (state) => state.users.userList,
      productList: (state) => state.products.productList,
      shortlists: (state) => state.companyShortlists.shortlists,
      sharedShortlists: (state) => state.sharedShortlists.shortlists,
      companyList: (state) => state.companies.companies,
      chatRooms: (state) => state.chat.chatRooms,
      userAnalytics: (state) => state.analytics.analyticsDocs,
    }),
    ...mapGetters("users", [
      "getUserEmailById",
      "getPushNotificationsSettings",
      "getUserNameByVismaId",
    ]),
    ...mapGetters("priceLists", ["getPriceListById"]),
    ...mapGetters("settings", ["getCompanyNameByVismaId"]),
    ...mapGetters("products", [
      "getProductByVismaId",
      "getProductByFirebaseId",
    ]),
    ...mapGetters("companies", [
      "getCompanyByVismaId",
      "getCompanyAccountOwnerByVismaId",
    ]),
    ...mapGetters("languages", ["enabledLanguages"]),
  },
  methods: {
    addPostSlugToChatMessages: async function () {
      console.log("lessgo");
      const postSlugs = {};
      const postsSnap = await db
        .collection("posts")
        .where("released", "==", true)
        .get();

      postsSnap.forEach((doc) => {
        const data = doc.data();

        if (doc.id === "settings" || data.slug === "") {
          return;
        }

        postSlugs[doc.id] = data.slug;
      });

      const chatRoomsSnap = await db.collection("chatRooms").get();
      console.log(chatRoomsSnap.size);

      for (const doc of chatRoomsSnap.docs) {
        console.log("start");
        const messagesSnap = await db
          .collection("chatRooms")
          .doc(doc.id)
          .collection("messages")
          .where("type", "==", "post")
          .get();

        if (messagesSnap.size > 0) {
          for (const message of messagesSnap.docs) {
            const data = message.data();

            if (data.postId !== undefined && data.postSlug === undefined) {
              const postSlug = postSlugs[data.postId];
              if (postSlug !== undefined) {
                await db
                  .collection("chatRooms")
                  .doc(doc.id)
                  .collection("messages")
                  .doc(message.id)
                  .update({
                    postSlug: postSlug,
                  });
                console.log("updated");
              }
            }
          }
        }

        console.log("end");
      }
      console.log("all done");
    },
    fixNewsSlugs: async function () {
      const snap = await db.collection("posts").get();

      const slugs = {};

      snap.forEach((doc) => {
        const data = doc.data();
        if (doc.id === "settings" || data.slug === "") {
          return;
        }

        if (slugs[data.slug] === undefined) {
          slugs[data.slug] = 1;
        } else {
          slugs[data.slug]++;

          // Run second execution after postSlugs is set.
          // db.collection("posts")
          //   .doc(doc.id)
          //   .update({
          //     slug: data.slug + "-" + slugs[data.slug],
          //   })
          //   .then(() => {
          //     console.log("done");
          //   });
        }
      });

      // Run first execution then comment out.
      // db.collection("posts")
      //   .doc("settings")
      //   .update({
      //     postSlugs: slugs,
      //   })
      //   .then(() => {
      //     console.log("done");
      //   });
      console.log(slugs);
    },
    cloneWebPage: async function () {
      console.log("ello");
      const doc = await db.collection("webPages").doc("omOss").get();

      const data = doc.data();

      await db.collection("webPages").doc("kontaktaOss").set(data);
    },
    setupWebPageMenus: async function () {
      console.log(this.enabledLanguages);
      const topMenuLoggedIn = {};
      const topMenuLoggedOut = {};
      const botMenuLoggedIn = {};
      const botMenuLoggedOut = {};
      const footerMenu1 = {};
      const footerMenu2 = {};
      const footerMenu3 = {};
      const footerMenuBot = {};
      this.enabledLanguages.forEach((lang) => {
        topMenuLoggedIn[lang.code] = [
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
        ];
        topMenuLoggedOut[lang.code] = [
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
        ];
        botMenuLoggedIn[lang.code] = [
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
        ];
        botMenuLoggedOut[lang.code] = [
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
        ];

        footerMenu1[lang.code] = [
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
        ];

        footerMenu2[lang.code] = [
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
        ];

        footerMenu3[lang.code] = [
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
        ];

        footerMenuBot[lang.code] = [
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
        ];
      });

      await db.collection("webPages").doc("menus").set({
        topMenuLoggedIn: topMenuLoggedIn,
        topMenuLoggedOut: topMenuLoggedOut,
        botMenuLoggedIn: botMenuLoggedIn,
        botMenuLoggedOut: botMenuLoggedOut,
        footerMenu1: footerMenu1,
        footerMenu2: footerMenu2,
        footerMenu3: footerMenu3,
        footerMenuBot: footerMenuBot,
      });

      console.log("done");
    },
    addPublicToAllExistingUpsellLists: async function () {
      const snap = await db.collection("upsellLists").get();

      snap.forEach((doc) => {
        const data = doc.data();

        if (data.public === undefined) {
          db.collection("upsellLists")
            .doc(doc.id)
            .update({
              public: false,
            })
            .then(() => {
              console.log("updated");
            });
        }
      });
    },
    addPublicToAllExistingPosts: async function () {
      const postsSnap = await db.collection("posts").get();

      postsSnap.forEach((doc) => {
        const data = doc.data();

        if (data.public === undefined) {
          db.collection("posts")
            .doc(doc.id)
            .update({
              public: false,
            })
            .then(() => {
              console.log("updated");
            });
        }
      });
    },
    addSlugToAllExistingPosts: async function () {
      const postsSnap = await db.collection("posts").get();

      postsSnap.forEach((doc) => {
        const data = doc.data();

        if (data.mainTitle === undefined) {
          console.log(doc.id);
          return;
        }

        if (data.slug === undefined) {
          const slug = slugify(data.mainTitle);
          db.collection("posts")
            .doc(doc.id)
            .update({
              slug: slug,
            })
            .then(() => {
              console.log("updated");
            });
        }
      });
    },
    test111: async function () {
      const usersSnap = db.collection("users").get();
      usersSnap.then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          const data = doc.data();
          if (data.chatNotifications === undefined) {
            console.log(doc.id);
            await db.collection("users").doc(doc.id).update({
              chatNotifications: {},
            });
          }
        });
      });

      // const now = new Date();

      // const checkDate = new Date(); // Timezones etc not really important here.
      // checkDate.setDate(checkDate.getDate() - 30);

      // const companyDoc = await db
      //   .collection("companies")
      //   .doc("7Mh9y4nP7jNgjsT4e0KC")
      //   .get();

      // const companyData = companyDoc.data();
      // const priceGroup1 = companyData.companyPriceGroup.toString();
      // const priceGroup2 = companyData.companyPriceGroup2.toString();
      // const priceGroup3 = companyData.companyPriceGroup3.toString();
      // const companyCategory = companyData.companyCategory.toString();

      // console.log(checkDate);
      // const posts = [];
      // const postsSnap = await db
      //   .collection("posts")
      //   .where("createdAt", ">", checkDate)
      //   .where("status", "==", "publish")
      //   .get();

      // postsSnap.forEach((postDoc) => {
      //   const postId = postDoc.id;
      //   const postData = postDoc.data();

      //   let endTime = null;
      //   if (postData.endTime) {
      //     endTime = postData.endTime.toDate();
      //   }
      //   const selections = postData.selections;

      //   console.log(postId);

      //   console.log(companyCategory);
      //   if (postId === "LXZqEilupmOxchJxOPdT") {
      //     console.log(postData);
      //   }

      //   if (
      //     (endTime !== null && endTime < now) ||
      //     selections.excludedPriceGroups1.includes(priceGroup1) ||
      //     selections.excludedPriceGroups2.includes(priceGroup2) ||
      //     selections.excludedPriceGroups3.includes(priceGroup3)
      //   ) {
      //     if (postId === "LXZqEilupmOxchJxOPdT") {
      //       console.log(endTime !== null && endTime > now);
      //     }
      //     return;
      //   }

      //   console.log(selections.selectedCompanyCategories);
      //   if (
      //     selections.selectedPriceGroups1.includes(priceGroup1) ||
      //     selections.selectedPriceGroups2.includes(priceGroup2) ||
      //     selections.selectedPriceGroups3.includes(priceGroup3) ||
      //     selections.selectedCompanyCategories.includes(companyCategory)
      //   ) {
      //     posts.push(postId);
      //   }
      // });

      // console.log(posts);

      // console.log(postsSnap.size);

      // const snap = await db.collection("companies").get();

      // snap.docs.map(async (doc) => {
      //   const data = doc.data();
      //   const dataDoc = await db
      //     .collection("companiesData")
      //     .doc(data.vismaId.toString())
      //     .get();
      //   if (!dataDoc.exists) {
      //     console.log(data.vismaId);
      //     await db
      //       .collection("companiesData")
      //       .doc(data.vismaId.toString())
      //       .set({
      //         productsBought: {},
      //       });
      //   }
      // });

      // snap.docs.map(async (doc) => {
      //   const data = doc.data();
      //   if (data.posts === undefined) {
      //     await db.collection("companies").doc(doc.id).update({
      //       posts: [],
      //     });
      //     console.log("updated posts on " + doc.id);
      //   }
      // });

      // const snap = db
      //   .collection("orders")
      //   .where("vismaId", "==", 1740533)
      //   .get();
      // snap.then((querySnapshot) => {
      //   querySnapshot.forEach((doc) => {
      //     console.log(doc.id);
      //     console.log(doc.data());
      //   });
      // });
      // const snap = await db.collection("products").get();
      // snap.forEach((doc) => {
      //   const data = doc.data();

      //   console.log(data.slug + " - " + data.vismaId);
      // });
      // const snap = await db
      //   .collection("orders")
      //   .where("vismaId", "==", 550898)
      //   .get();

      // snap.forEach((doc) => {
      //   console.log(doc.id);
      //   console.log(doc.data());
      // });

      // const doc = await db.collection("app").doc("productSettings").get();

      // const categoryGroups = doc.data().categoryGroups;

      // const keys = Object.keys(categoryGroups);

      // console.log(keys);

      // const dummyData = {};

      // keys.forEach((key) => {
      //   dummyData[key] = "";
      // });

      // console.log(dummyData);

      // await db
      //   .collection("app")
      //   .doc("productSettings")
      //   .update({
      //     ["categoryGroupsTranslated.fr"]: dummyData,
      //     ["categoryGroupsTranslated.it"]: dummyData,
      //     ["categoryGroupsTranslated.th"]: dummyData,
      //   });
    },
    kfSetTest1Group: async function () {
      if (this.appBrand !== "kvalitetsfisk") {
        console.log("wrong brand");
        return;
      }

      const groupNone = [
        106455, 105721, 105607, 105407, 105237, 106456, 105576, 105577, 105552,
        105702, 105334, 106075, 105608, 105672, 105677, 105609, 103896, 105242,
        106071, 106151, 102104, 104059, 105272, 103445, 102526, 105959, 105325,
        105960, 103775, 106152, 105290, 103812, 105908, 106233, 103323, 105377,
        104340, 104060, 105835, 102992, 104182, 105291, 106113, 104870, 103675,
        106017, 106111, 105942, 106072, 105981, 106114, 103446, 105856, 103444,
        105227, 104517, 105213, 103776, 103191, 101974, 105841, 102310, 105842,
        105451, 106123, 105220, 105967, 103268, 105720, 104040, 105535, 106006,
        106039, 106073, 105375, 104818, 104817, 105730, 105613, 103672, 103025,
        103051, 102758, 105261, 104417, 105586, 104728, 105683, 105817, 104008,
        103042, 106661, 106660, 106655, 106663, 115907, 106774, 115869, 106653,
        104581, 104234, 104457, 104591, 105124, 104559, 105004, 104777, 106704,
        106656, 104359, 104168, 106664, 106658, 104602, 104824, 106759, 106657,
        104602, 104824, 106759,
      ];

      const snap = await db.collection("companies").get();

      snap.forEach((doc) => {
        if (groupNone.includes(doc.data().vismaId)) {
          db.collection("companies")
            .doc(doc.id)
            .update({
              testGroup: "none",
            })
            .then(() => {
              console.log("updated none");
            });
        } else {
          db.collection("companies")
            .doc(doc.id)
            .update({
              testGroup: "Test1",
            })
            .then(() => {
              console.log("updated Test1");
            });
        }
      });
    },
    fixAnalyticsFirstWeekOfYear: async function () {
      const snap = await db.collection("analytics").get();

      console.log(snap.size);
      let updatePromises = [];

      snap.forEach((doc) => {
        updatePromises.push(
          db
            .collection("analytics")
            .doc(doc.id)
            .collection("logs")
            .doc("20241")
            .set({})
        );
      });

      await Promise.all(updatePromises);
      console.log("all done");
    },
    test: async function () {
      // const snap = await db
      //   .collection("orders")
      //   .where("vismaId", "==", 435543)
      //   .get();
      // console.log(snap.size);
      // snap.forEach((doc) => {
      //   console.log(doc.id);
      //   console.log(doc.data());
      // });

      db.collection("orders")
        .where("vismaId", "==", 1716427)
        .get()
        .then((snap) => {
          console.log(snap.size);
          snap.forEach((doc) => {
            console.log(doc.id);
            console.log(doc.data());
          });
        });

      // console.log("start");
      // let companyObject = {};
      // db.collection("companies")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       const data = doc.data();

      //       db.collection("companies").doc(doc.id).update({
      //         blockedDeliveryDays: [],
      //       }).
      //       then( () => {
      //         console.log("done");
      //       })
      // });

      // db.collection("app")
      //   .doc("companyList")
      //   .set({
      //     companies: companyObject,
      //   })
      //   .then(() => {
      //     console.log("done");
      //   })
      //   .catch((error) => {
      //     console.log("error");
      //     console.log(error);
      //   });
      // });

      // const snap = await db
      //   .collection("companies")
      //   .where("vismaId", "==", 100545)
      //   .get();

      // console.log(snap.size);

      // const data = snap.docs[0].data();

      // console.log(data);

      // const checkDate = new Date();
      // checkDate.setDate(checkDate.getDate() - 30);

      // db.collection("posts")
      //   .where("createdAt", ">", checkDate)
      //   .where("status", "==", "publish")
      //   .get()
      //   .then((snap) => {
      //     snap.forEach((doc) => {
      //       const data = doc.data();
      //       const postId = doc.id;

      //       console.log(doc.id);
      //     });
      //   });

      // const snap = await db.collection("chatRooms").get();

      // snap.forEach(async (doc) => {
      //   const messageSnap = await db
      //     .collection("chatRooms")
      //     .doc(doc.id)
      //     .collection("messages")
      //     .get();

      //   if (messageSnap.size > 0) {
      //     messageSnap.forEach((message) => {
      //       const data = message.data();

      //       if (
      //         data.timestamp instanceof firebase.firestore.Timestamp ===
      //         false
      //       ) {
      //         console.log(doc.id + " - " + message.id);

      //         db.collection("chatRooms")
      //           .doc(doc.id)
      //           .collection("messages")
      //           .doc(message.id)
      //           .delete();
      //       }
      //     });
      //   }
      // });

      // const snap = await db
      //   .collection("products")
      //   .where("vismaId", "==", "7566")
      //   .get();

      // console.log(snap.size);

      // snap.forEach((doc) => {
      //   console.log(doc.id);
      //   console.log(doc.data());
      // });
    },
    addCurrencyToUsers: async function () {
      const settingsDoc = await db.collection("app").doc("settings").get();
      const currency = settingsDoc.data().currency;

      const usersSnap = await db.collection("users").get();

      usersSnap.forEach(async (user) => {
        await db.collection("users").doc(user.id).update({
          currency: currency,
        });
        console.log("done on " + user.id);
      });
    },
    removeDeletedPostsFromCompanies: async function () {
      // console.log(this.companyList);

      const now = Date.now();

      let posts = {};
      let deletedPosts = {};

      this.companyList.forEach((company) => {
        if (company.posts !== undefined && company.posts.length > 0) {
          for (const postId of company.posts) {
            if (posts[postId] === undefined) {
              posts[postId] = [];
            }

            posts[postId].push(company.id);
          }
        }
      });

      await Promise.all(
        Object.keys(posts).map(async (postId) => {
          const postDoc = await db.collection("posts").doc(postId).get();
          if (!postDoc.exists) {
            deletedPosts[postId] = posts[postId];
          } else {
            const data = postDoc.data();
            if (data.endTime && data.endTime.toDate().getTime() < now) {
              deletedPosts[postId] = posts[postId];
            }
          }
        })
      );

      // console.log(Object.keys(deletedPosts).length);
      console.log(deletedPosts);
      Object.keys(deletedPosts).map(async (postId) => {
        // console.log(deletedPosts[postId].length);

        const numBatches = Math.ceil(deletedPosts[postId].length / 500);

        for (let i = 0; i < numBatches; i++) {
          const batch = db.batch();
          const companyIds = deletedPosts[postId].slice(i * 500, (i + 1) * 500);

          for (const companyId of companyIds) {
            const companyRef = db.collection("companies").doc(companyId);
            batch.update(companyRef, {
              posts: firebase.firestore.FieldValue.arrayRemove(postId),
            });
          }

          await batch.commit();
          console.log(postId + " done batch " + (i + 1) + " of " + numBatches);
        }

        console.log(
          'Post "' +
            postId +
            '" removed from ' +
            deletedPosts[postId].length +
            " companies"
        );

        // for (const companies of deletedPosts[postId]) {
        // console.log(companies);
        // try {
        //   await db
        //     .collection("companies")
        //     .doc(companyId)
        //     .update({
        //       posts: firebase.firestore.FieldValue.arrayRemove(postId),
        //     });
        //   console.log("done");
        // } catch (e) {
        //   console.log("error: " + e.message);
        // }
        // }

        // deletedPosts[postId].map(async (companyId) => {
        //   try {
        //     await db
        //       .collection("companies")
        //       .doc(companyId)
        //       .update({
        //         posts: firebase.firestore.FieldValue.arrayRemove(postId),
        //       });
        //     console.log("done");
        //   } catch (e) {
        //     console.log("error: " + e.message);
        //   }
        // });
      });

      console.log("all done");
    },
    clearAllAdminOrderNotifications: async function () {
      await db.collection("app").doc("adminOrderNotifications").delete();
      console.log("done");
    },
    removeDeletedShortlistsFromCompanies: async function () {
      console.log(this.companyList);

      let shortlists = {};
      let deletedShortlists = {};

      this.companyList.forEach((company) => {
        if (Object.keys(company.shortlists).length > 0) {
          for (const [key, value] of Object.entries(company.shortlists)) {
            if (shortlists[key] === undefined) {
              shortlists[key] = [];
            }

            shortlists[key].push(company.id);
          }
        }
      });
      console.log(shortlists);

      await Promise.all(
        Object.keys(shortlists).map(async (shortlistId) => {
          const shortlistDoc = await db
            .collection("shortlists")
            .doc(shortlistId)
            .get();
          if (!shortlistDoc.exists) {
            deletedShortlists[shortlistId] = shortlists[shortlistId];
          }
        })
      );

      console.log(deletedShortlists);

      await Promise.all(
        Object.keys(deletedShortlists).map(async (shortlistId) => {
          await Promise.all(
            deletedShortlists[shortlistId].map(async (companyId) => {
              try {
                await db
                  .collection("companies")
                  .doc(companyId)
                  .update({
                    ["shortlists." + shortlistId]:
                      firebase.firestore.FieldValue.delete(),
                  });
                console.log("done");
              } catch (e) {
                console.log("error: " + e.message);
              }
            })
          );
        })
      );

      console.log("all done");
    },
    temp2: async function () {
      const snap = await db
        .collection("app")
        .doc("registerAnalytics")
        .collection("completed")
        .orderBy("timestamp", "desc")
        .get();

      snap.forEach((doc) => {
        // console.log(doc.data());
        const data = doc.data();
        const user = this.userList.find((user) => user.vismaId === data.userId);
        let userName = "Deleted user";
        const userId = data.userId;
        const newCompany = data.newCompany ? "new" : "existing";
        if (user !== undefined) {
          userName = user.name;
        }
        const company = this.getCompanyByVismaId(data.companyId);
        let companyName = "Deleted company";
        let accountOwnerName = "No account owner";
        if (company !== undefined) {
          companyName = company.name;
          const accountOwnerId = this.getCompanyAccountOwnerByVismaId(
            company.vismaId
          );
          accountOwnerName = this.getUserNameByVismaId(accountOwnerId);
        }

        console.log(
          userId +
            "," +
            userName.trim() +
            "," +
            companyName +
            "," +
            accountOwnerName +
            "," +
            newCompany
        );
      });
    },
    temp1: async function () {
      console.log("???");
      const snap = await db
        .collection("analytics")
        .where("active", "==", true)
        .get();

      console.log(snap.size);

      snap.forEach((doc) => {
        const data = doc.data();

        let lastOpen = "Never";
        let lastOpenWeb = "Never";

        if (data.lastOpen !== undefined && data.lastOpen !== null) {
          lastOpen = data.lastOpen.toDate().toISOString().split("T")[0];
        }

        if (data.lastOpenWeb !== undefined && data.lastOpenWeb !== null) {
          lastOpenWeb = data.lastOpenWeb.toDate().toISOString().split("T")[0];
        }

        const user = this.userList.find((user) => user.id === doc.id);
        if (user !== undefined) {
          const company = this.getCompanyByVismaId(user.companyId);
          let companyName = "Deleted company";
          let accountOwnerName = "No account owner";
          if (company !== undefined) {
            companyName = company.name;
            const accountOwnerId = this.getCompanyAccountOwnerByVismaId(
              company.vismaId
            );
            accountOwnerName = this.getUserNameByVismaId(accountOwnerId);
          }
          console.log(
            user.email +
              "," +
              companyName +
              "," +
              lastOpen +
              "," +
              lastOpenWeb +
              "," +
              accountOwnerName
          );
        }

        // snap.forEach((doc) => {
        //   const data = doc.data();

        //   if (data.appVersion !== undefined && data.appVersion !== "2.23.1") {
        //     const user = this.userList.find((user) => user.id === doc.id);
        //     if (user !== undefined) {
        //       const company = this.getCompanyByVismaId(user.companyId);
        //       let companyName = "Deleted company";
        //       let accountOwnerName = "No account owner";
        //       if (company !== undefined) {
        //         companyName = company.name;
        //         const accountOwnerId = this.getCompanyAccountOwnerByVismaId(
        //           company.vismaId
        //         );
        //         accountOwnerName = this.getUserNameByVismaId(accountOwnerId);
        //       }
        //       console.log(
        //         user.email + "," + companyName + "," + accountOwnerName
        //       );
        //     }
        //   }
        // if (data.lastOpen === undefined) {
        //   const user = this.userList.find((user) => user.id === doc.id);
        //   if (user !== undefined) {
        //     const company = this.getCompanyByVismaId(user.companyId);
        //     let companyName = "Deleted company";
        //     let accountOwnerName = "No account owner";
        //     if (company !== undefined) {
        //       companyName = company.name;
        //       const accountOwnerId = this.getCompanyAccountOwnerByVismaId(
        //         company.vismaId
        //       );
        //       accountOwnerName = this.getUserNameByVismaId(accountOwnerId);
        //     }

        //     console.log(
        //       user.email + "," + companyName + "," + accountOwnerName
        //     );
        //   }
        // }
      });
      // const products = await db.collection("products").get();
      // console.log(products.size);

      // products.forEach((product) => {
      //   const data = product.data();

      //   if (data.species === undefined) {
      //     console.log(product.id);
      //   }

      //   if (data.vismaId === "3380") {
      //     console.log(data);
      //   }
      // });
      // const doc = await db
      //   .collection("app")
      //   .doc("adminOrderNotifications")
      //   .get();
      // console.log(doc.data());
    },
    fixPinDeliver: async function () {
      const checkDate = new Date();
      checkDate.setDate(checkDate.getDate() - 250);

      const checkDate2 = new Date();
      checkDate2.setDate(checkDate2.getDate() - 300);

      const snap = await db
        .collection("orders")
        .where("deliveryDate", "<", checkDate)
        .where("deliveryDate", ">", checkDate2)
        .get();

      console.log(snap.size);

      let ids = [];

      snap.forEach((doc) => {
        const data = doc.data();

        if (
          data.deliveryInfo !== undefined &&
          data.deliveryInfo.status !== undefined &&
          data.deliveryInfo.status !== "DELIVERED" &&
          data.deliveryInfo.status !== "NOT_DELIVERED" &&
          data.deliveryInfo.status !== "DELIVERED_NOT_HOME"
        ) {
          ids.push({
            id: doc.id,
            vismaId: data.vismaId,
            status: data.deliveryInfo.status,
            trackingUrl: data.deliveryInfo.trackingUrl,
          });
        }
      });

      console.log(ids);

      ids.forEach((id) => {
        console.log("firebase id: " + id.id);
        console.log("Visma id: " + id.vismaId);
        console.log("Status: " + id.status);
        console.log("Tracking url: " + id.trackingUrl);
        console.log(" ----- ");
      });

      await Promise.all(
        ids.map(async (id) => {
          await db
            .collection("orders")
            .doc(id.id)
            .update({
              ["deliveryInfo.status"]: "DELIVERED",
            });
          console.log("done");
        })
      );
      console.log("done done");
    },
    addPopupsDocToAllUsers: async function () {
      const users = await db.collection("users").get();
      users.forEach((user) => {
        db.collection("users")
          .doc(user.id)
          .collection("data")
          .doc("popups")
          .set({
            pushDisabled: [],
            pushReminder: [],
          })
          .then(() => {
            console.log("done");
          });
      });
    },
    fixPushNotificationSettings: async function () {
      console.log(this.userList);

      let i = 0;
      await Promise.all(
        this.userList.map(async (user) => {
          const pushNotifications = this.getPushNotificationsSettings(user.id);

          if (pushNotifications["incomingDelivery"] !== undefined) {
            i++;
            console.log(
              "removing incomingDelivery from " + user.id + " - " + i
            );
            await db
              .collection("users")
              .doc(user.id)
              .collection("data")
              .doc("settings")
              .update({
                "pushNotifications.incomingDelivery":
                  firebase.firestore.FieldValue.delete(),
              });
          }
        })
      );

      console.log("done on " + i);
    },
    addDaysToDeliveryCutOff: async function () {
      let deliveryCutOff = JSON.parse(
        JSON.stringify(this.appSettings.deliveryCutOff)
      );

      let newVal = {};

      for (const [key, value] of Object.entries(deliveryCutOff)) {
        newVal[key] = value;
        newVal[key].days = 0;
      }
      console.log(newVal);
      console.log(this.appSettings.deliveryCutOff);

      db.collection("app")
        .doc("settings")
        .update({
          deliveryCutOff: newVal,
        })
        .then(() => {
          console.log("success");
        });
    },
    test18: function () {
      // db.collection("orders")
      //   .where("vismaId", "==", 388156)
      //   .get()
      //   .then((snap) => {
      //     console.log(snap.size);
      //     console.log(snap.docs[0].id);
      //   });

      // db.collection("companies")
      //   .get()
      //   .then((snap) => {
      //     snap.forEach((doc) => {
      //       const data = doc.data();
      //       console.log(data.deliveryRoute);
      //     });
      //   });
      db.collection("products")
        .get()
        .then((snap) => {
          console.log(snap.size);
          snap.forEach((doc) => {
            const data = doc.data();
            if (data.stockStatus === undefined) {
              console.log(doc.id);
            }
          });
        });
    },
    fixAccountOwnerOnPersonalChatRooms: function () {
      const personalChatRooms = this.chatRooms.personal;

      for (const [key, value] of Object.entries(personalChatRooms)) {
        // console.log(value);
        const user = this.userList.find((user) => user.id === value.identifier);

        if (user === undefined) {
          console.log("user not found");
          continue;
        }

        const company = this.companyList.find(
          (company) => company.vismaId == user.companyId
        );

        if (company === undefined) {
          console.log("company not found");
          continue;
        }

        if (value.accountOwner !== company.accountOwnerVismaId) {
          db.collection("chatRooms")
            .doc(key)
            .update({
              accountOwnerVismaId: company.accountOwnerVismaId,
            })
            .then(() => {
              console.log("updated accountOwnerVismaId on chatRoom " + key);
            });
        }
      }
    },
    addCreatedUpdatedToExistingShortlists: function () {
      db.collection("shortlists")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            const data = doc.data();
            if (data.created === undefined || data.updated === undefined) {
              db.collection("shortlists")
                .doc(doc.id)
                .update({
                  created: firebase.firestore.FieldValue.serverTimestamp(),
                  updated: firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then(() => {
                  console.log("updated");
                });
            }
          });
        });
    },
    addSelectionFieldsToAllUpsellLists: function () {
      db.collection("upsellLists")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("upsellLists")
              .doc(doc.id)
              .update({
                selectedPriceGroups1: [],
                selectedPriceGroups2: [],
              })
              .then(() => {
                console.log("updated");
              });
          });
        });
    },
    addTypeToAllUpsellLists: function () {
      db.collection("upsellLists")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("upsellLists")
              .doc(doc.id)
              .update({
                type: "cart",
              })
              .then(() => {
                console.log("updated");
              });
          });
        });
    },
    addHomeUpsellToAllCompanies: function () {
      db.collection("companies")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            if (doc.data().homeUpsell === undefined) {
              // console.log(doc.id);
              db.collection("companies")
                .doc(doc.id)
                .update({
                  homeUpsell: [],
                })
                .then(() => {
                  console.log("updated");
                });
            }
          });
        });
    },
    // test: function () {},
    addPlacedOrderAndSignedInToAnalyticsDocuments: function () {
      db.collection("analytics")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("analytics")
              .doc(doc.id)
              .update({
                hasPlacedOrder: false,
                hasSignedIn: false,
              })
              .then(() => {
                console.log("updated done");
              });
          });
        });
    },
    addAccountOwnerToAnalyticsDocument: function () {
      db.collection("analytics")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            const data = doc.data();

            if (data.active) {
              db.collection("users")
                .doc(doc.id)
                .get()
                .then((userDoc) => {
                  const company = this.getCompanyByVismaId(
                    userDoc.data().companyId
                  );

                  if (company !== undefined) {
                    db.collection("analytics")
                      .doc(doc.id)
                      .update({
                        accountOwner: company.accountOwnerVismaId,
                      })
                      .then(() => {
                        console.log("updated active");
                      });
                  } else {
                    db.collection("analytics")
                      .doc(doc.id)
                      .update({
                        accountOwner: 0,
                      })
                      .then(() => {
                        console.log("updated not active, no company");
                      });
                  }
                });
            } else {
              db.collection("analytics")
                .doc(doc.id)
                .update({
                  accountOwner: 0,
                })
                .then(() => {
                  console.log("updated not active");
                });
            }
          });
        });
    },
    addCompaniesToRemainingUsers: function () {
      console.log("here");
      db.collection("users")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            const data = doc.data();

            if (data.companies === undefined) {
              if (data.companyId === undefined) {
                console.log("no company: ", doc.id);
              } else {
                db.collection("users")
                  .doc(doc.id)
                  .update({
                    companies: [data.companyId],
                  })
                  .then(() => console.log("updated"));
              }
            }
          });
        });
    },
    test17: function () {
      // db.collection("app")
      //   .doc("adminOrderNotifications")
      //   .delete()
      //   .then((doc) => {
      //     console.log("deleted");
      //   });
      // Date 30 days ago
      // const checkDate = new Date();
      // checkDate.setDate(checkDate.getDate() - 30);
      // // Date now
      // const now = new Date();
      // db.collection("posts")
      //   .where("createdAt", ">", checkDate)
      //   .where("endTime", ">", now)
      //   .get()
      //   .then((snap) => {
      //     console.log(snap.size);
      //   });
      // db.collection("companies")
      //   .get()
      //   .then((snap) => {
      //     snap.forEach((doc) => {
      //       const data = doc.data();
      //       if (data.name.includes("Daily Sushi")) {
      //         return;
      //       } else {
      //         // console.log("update");
      //         db.collection("companies")
      //           .doc(doc.id)
      //           .update({
      //             testGroup: "Test1",
      //           })
      //           .then(() => {
      //             console.log("Updated");
      //           });
      //       }
      //     });
      //   });
      // db.collection("orders")
      //   .where("vismaId", "==", 1599653)
      //   .get()
      //   .then((snap) => {
      //     snap.forEach((doc) => {
      //       console.log(doc.id);
      //     });
      //   });
      // db.collection("chatRooms")
      //   .get()
      //   .then((snap) => {
      //     console.log(snap.size);
      //     snap.forEach((doc) => {
      //       const data = doc.data();
      //       if (data.name === undefined) {
      //         console.log(doc.id);
      //       }
      //     });
      //   });
      // db.collection("app")
      //   .doc("chatRooms")
      //   .get()
      //   .then((doc) => {
      //     console.log(doc.data());
      //     const personal = doc.data().personal;
      //     const company = doc.data().company;
      //     for (const [key, value] of Object.entries(personal)) {
      //       if (value.name === undefined) {
      //         console.log(key);
      //       }
      //     }
      //     for (const [key, value] of Object.entries(company)) {
      //       if (value.name === undefined) {
      //         console.log(key);
      //         console.log(value);
      //       }
      //     }
      //     // const data = doc.data();
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // db.collection("app")
      //   .doc("chatRooms")
      //   .update({
      //     ["company.JAGXPBLjYToKBXoiOaxV.accountOwner"]: 7029,
      //   })
      //   .then(() => {
      //     console.log("done");
      //   });
    },
    test16: function () {
      db.collection("companies")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            const data = doc.data();
            if (data.route === undefined) {
              console.log(doc.id);
            }
          });
        });
    },
    createAdminCompanyList: function () {
      let companiesArr = [];
      db.collection("companies")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            const data = doc.data();

            companiesArr.push({
              firebaseId: doc.id,
              vismaId: data.vismaId,
              accountOwner: data.accountOwnerVismaId,
              productsPrice: data.productsPrice,
              productsSotck: data.productsStock,
              name: data.name,
              allowCredit: data.allowCredit,
            });
          });

          db.collection("app")
            .doc("adminCompanyList")
            .set(
              {
                companies: companiesArr,
              },
              { merge: false }
            )
            .then(() => {
              console.log("done");
            });
        });
    },
    test15: function () {
      db.collection("users")
        .where("role", "==", "user")
        .get()
        .then((snap) => {
          console.log(snap.size);
          snap.forEach((doc) => {
            const data = doc.data();

            if (data.email.toLowerCase() == undefined) {
              console.log(doc.id);
            }
          });
        });
    },
    test14: function () {
      db.collection("users")
        .get()
        .then((snap) => {
          console.log(snap.size);
          snap.forEach((user) => {
            const data = user.data();
            if (data.companies === undefined) {
              console.log(data);

              db.collection("users")
                .doc(user.id)
                .update({
                  companies: [],
                })
                .then(() => {
                  console.log("done");
                });
            }
          });
        });
    },
    addUpsellFieldToCompany: function () {
      db.collection("companies")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("companies")
              .doc(doc.id)
              .update({
                upsell: null,
              })
              .then(() => {
                console.log("done");
              });
          });
        });
    },
    setupRecommendedGroupsFromBigQuery: function () {
      db.collection("companies")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            const data = doc.data();

            const found = abTestGroups.find(
              (entry) => parseInt(entry.custno) === data.vismaId
            );

            db.collection("companies")
              .doc(doc.id)
              .update({
                testGroup: found === undefined ? "none" : found.group,
              })
              .then(() => {
                console.log("updated");
              });
          });
        });
    },
    createControlGroupRecommendations: function () {
      let sharedShortlists = [];

      // Get all shared shortlists
      const sharedShortlistsPromise = new Promise((resolve) => {
        db.collection("shortlists")
          .where("type", "==", "shared")
          .get()
          .then((snap) => {
            snap.forEach((doc) => {
              let companies = [];
              doc.data().companies.forEach((comp) => {
                companies.push(comp.vismaId);
              });

              sharedShortlists.push({
                products: doc.data().products,
                companies: companies,
              });
            });
            resolve();
          });
      });

      Promise.all([sharedShortlistsPromise]).then(() => {
        console.log(sharedShortlists);

        db.collection("companies")
          .where("testGroup", "==", "Control")
          .get()
          .then((snap) => {
            // Go over each company in control group
            snap.forEach((doc) => {
              const vismaId = doc.data().vismaId;
              const firebaseId = doc.id;

              let prodArr = [];

              // Get all company shortlists
              const p1 = new Promise((resolve) => {
                db.collection("shortlists")
                  .where("companyId", "==", vismaId)
                  .get()
                  .then((snap) => {
                    if (snap.size > 0) {
                      snap.forEach((doc) => {
                        const data = doc.data();

                        Object.keys(data.products).forEach((key) => {
                          prodArr.push(key);
                        });
                      });
                    }

                    sharedShortlists.forEach((sharedShortlist) => {
                      if (sharedShortlist.companies.includes(vismaId)) {
                        Object.keys(sharedShortlist.products).forEach((key) => {
                          prodArr.push(key);
                        });
                      }
                    });

                    resolve();
                  });
              });

              Promise.all([p1]).then(() => {
                const prodArrUnique = [...new Set(prodArr)];
                let prodObj = {};

                let prodArrShuffled = prodArrUnique
                  .map((value) => ({ value, sort: Math.random() }))
                  .sort((a, b) => a.sort - b.sort)
                  .map(({ value }) => value);

                if (prodArrShuffled.length > 60) {
                  prodArrShuffled.length = 60;
                }

                prodArrShuffled.forEach((firebaseId) => {
                  const prod = this.getProductByFirebaseId(firebaseId);

                  if (prod.name !== "Deleted product") {
                    prodObj[prod.vismaId] = true;
                  }
                });

                db.collection("companies")
                  .doc(firebaseId)
                  .update({
                    recommendedProducts: {},
                    recommendedShortlistProducts: prodObj,
                  })
                  .then(() => {
                    console.log("done updating " + vismaId);
                  });
              });
            });
          });
      });
    },
    setupRecommendedProductsFromBigQuery: function () {
      recommendedShortlists.forEach((row) => {
        let products = {};

        row.shortlist.forEach((prod) => {
          if (this.getProductByVismaId(prod.prodno) !== undefined) {
            products[prod.prodno] = true;
          }
        });

        db.collection("companies")
          .where("vismaId", "==", parseInt(row.custno))
          .get()
          .then((snap) => {
            if (snap.size > 0) {
              if (snap.docs[0].data().testGroup !== "none") {
                // console.log("do update");
                db.collection("companies")
                  .doc(snap.docs[0].id)
                  .update({
                    recommendedProducts: products,
                    recommendedShortlistProducts: {},
                  })
                  .then(() => {
                    console.log("updated");
                  });
              } else {
                console.log("In group none");
              }
            } else {
              console.log("does not exist");
            }
          });
      });
    },
    addSettingsDocToUser: async function () {
      const appSettings = await db.collection("app").doc("settings").get();
      const defaultPushNotifications =
        appSettings.data().defaultPushNotifications;

      db.collection("users")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("users")
              .doc(doc.id)
              .collection("data")
              .doc("settings")
              .set({
                pushNotifications: defaultPushNotifications,
              })
              .then(() => {
                console.log("done");
              });
          });
        });
    },
    test13: function () {
      db.collection("products")
        .get()
        .then((snap) => {
          console.log(snap.size);
        });

      db.collection("app")
        .doc("productList")
        .get()
        .then((doc) => {
          console.log(doc.data());
        });
    },
    checkCartBug: function () {
      db.collection("users")
        .get()
        .then((snap) => {
          snap.forEach((user) => {
            db.collection("users")
              .doc(user.id)
              .collection("data")
              .doc("cart")
              .get()
              .then((doc) => {
                for (const [key, value] of Object.entries(doc.data().cart)) {
                  if (value.note === undefined) {
                    console.log(user.id);
                  }
                }
              });
          });
        });
    },
    createAdminUsersList: function () {
      db.collection("users")
        .where("role", "==", "admin")
        .get()
        .then((snap) => {
          let fieldData = {};

          snap.forEach((doc) => {
            const data = doc.data();

            fieldData[data.vismaId.toString()] = {
              firebaseId: doc.id,
              vismaId: data.vismaId,
              name: data.name,
            };
          });

          db.collection("app")
            .doc("adminUsers")
            .set({
              admins: fieldData,
            })
            .then(() => {
              console.log("done!");
            });
        });
    },
    test12: function () {
      db.collection("users")
        .get()
        .then((snap) => {
          snap.forEach((doc) => [
            db
              .collection("users")
              .doc(doc.id)
              .collection("data")
              .doc("cart")
              .get()
              .then((cartDoc) => {
                // console.log(cartDoc.data().cart);
                const cart = cartDoc.data().cart;

                for (const [key, value] of Object.entries(cart)) {
                  if (value.note === undefined) {
                    console.log(doc.id);
                  }
                }
              }),
          ]);
        });
    },
    createNewAllChatRoomDoc: function () {
      db.collection("chatRooms")
        .get()
        .then((snap) => {
          let company = [];
          let personalTemp = [];
          let personal = [];

          snap.forEach((doc) => {
            const data = doc.data();

            if (data.type == "company") {
              company.push({
                id: doc.id,
                name: data.name,
                accountOwner: data.accountOwnerVismaId,
                type: "company",
                identifier: parseInt(data.identifier),
                timestamp: null,
                message: "",
              });
            } else if (data.type == "personal") {
              personalTemp.push({
                id: doc.id,
                name: data.name,
                accountOwner: data.accountOwnerVismaId,
                type: "personal",
                identifier: data.identifier,
                timestamp: null,
                message: "",
              });
            }
          });

          let personalPromises = personalTemp.map((user) => {
            return new Promise((resolve) => {
              db.collection("users")
                .doc(user.identifier)
                .get()
                .then((doc) => {
                  db.collection("companies")
                    .where("vismaId", "==", doc.data().companyId)
                    .get()
                    .then((snap) => {
                      if (snap.size > 0) {
                        user.companyName = snap.docs[0].data().name;
                        personal.push(user);
                      }
                      resolve();
                    });
                });
            });
          });

          Promise.all(personalPromises).then(() => {
            console.log("done");
            console.log(company);
            console.log(personal);

            let companyData = {};
            let personalData = {};
            let messagePromises = [];

            company.forEach((comp) => {
              companyData[comp.id] = comp;
              // companyData[comp.id].accountOwner = 1337;

              messagePromises.push(
                new Promise((resolve) => {
                  db.collection("chatRooms")
                    .doc(comp.id)
                    .collection("messages")
                    .orderBy("timestamp", "desc")
                    .limit(1)
                    .get()
                    .then((snap) => {
                      if (snap.size > 0) {
                        let index = 0;
                        let found = false;

                        for (const doc of snap.docs) {
                          const data = doc.data();
                          if (data.type === undefined || data.type !== "post") {
                            found = true;
                            break;
                          }
                          index++;
                        }

                        if (found) {
                          const messageData = snap.docs[index].data();
                          companyData[comp.id].timestamp =
                            messageData.timestamp;

                          let message = "";
                          if (messageData.message !== "") {
                            message = messageData.message;
                          } else if (
                            messageData.productId !== undefined &&
                            messageData.productId !== null
                          ) {
                            message = "<Product>";
                          } else if (
                            messageData.shortlistId !== undefined &&
                            messageData.shortlistId !== null
                          ) {
                            message = "<Shortlist>";
                          } else {
                            message = "<Image>";
                          }
                          companyData[comp.id].message = message;
                        }
                      }

                      resolve();
                    });
                })
              );
            });

            personal.forEach((pers) => {
              personalData[pers.id] = pers;
              // personalData[pers.id].accountOwner = 1337;

              messagePromises.push(
                new Promise((resolve) => {
                  db.collection("chatRooms")
                    .doc(pers.id)
                    .collection("messages")
                    .orderBy("timestamp", "desc")
                    .limit(1)
                    .get()
                    .then((snap) => {
                      if (snap.size > 0) {
                        let index = 0;
                        let found = false;

                        for (const doc of snap.docs) {
                          const data = doc.data();
                          if (data.type === undefined || data.type !== "post") {
                            found = true;
                            break;
                          }
                          index++;
                        }

                        if (found) {
                          const messageData = snap.docs[0].data();
                          personalData[pers.id].timestamp =
                            messageData.timestamp;
                          let message = "";
                          if (messageData.message !== "") {
                            message = messageData.message;
                          } else if (
                            messageData.productId !== undefined &&
                            messageData.productId !== null
                          ) {
                            message = "<Product>";
                          } else if (
                            messageData.shortlistId !== undefined &&
                            messageData.shortlistId !== null
                          ) {
                            message = "<Shortlist>";
                          } else {
                            message = "<Image>";
                          }
                          personalData[pers.id].message = message;
                        }
                      }

                      resolve();
                    });
                })
              );
            });

            Promise.all(messagePromises).then(() => {
              console.log("all done");
              console.log(personalData);
              console.log(companyData);

              db.collection("app")
                .doc("chatRooms")
                .set(
                  {
                    company: companyData,
                    personal: personalData,
                  },
                  { merge: false }
                )
                .then(() => {
                  console.log("done");
                });
            });
          });
        });
    },
    adminSyncFreightChainManually: function () {
      this.axios
        .post(
          this.appSettings.cloudFunctions + "adminSyncFreightChainManually",
          {
            env: this.$cookies.get("version"),
            brand: this.appBrand,
          }
        )
        .then((res) => {
          console.log(res);
        });
    },
    adminSyncFreightCompanyManually: function () {
      this.axios
        .post(
          this.appSettings.cloudFunctions + "adminSyncFreightCompanyManually",
          {
            env: this.$cookies.get("version"),
            brand: this.appBrand,
          }
        )
        .then((res) => {
          console.log(res);
        });
    },
    adminSyncFreightExternalManually: function () {
      this.axios
        .post(
          this.appSettings.cloudFunctions + "adminSyncFreightExternalManually",
          {
            env: this.$cookies.get("version"),
            brand: this.appBrand,
          }
        )
        .then((res) => {
          console.log(res);
        });
    },
    test11: function () {
      let newData = {};
      // deliveryData.dtos.forEach((entry) => {
      //   if (newData[entry.delMt] === undefined) {
      //     newData[entry.delMt] = [];
      //   }

      //   newData[entry.delMt].push({
      //     fromPostNo: entry.fromPostNo,
      //     toPostNo: entry.toPostNo,
      //     price: entry.price,
      //     fromWeight: entry.fromWeight,
      //     toWeight: entry.toWeight,
      //   });
      // });

      // for (const [key, value] of Object.entries(data)) {
      //   console.log(key);
      // }

      // db.collection("app")
      //   .doc("freight")
      //   .update({
      //     external: newData,
      //   })
      //   .then(() => {
      //     console.log("done");
      //   })
      //   .catch((error) => {
      //     console.log(error.message);
      //   });

      db.collection("app")
        .doc("freight")
        .get()
        .then((doc) => {
          console.log(doc.data().chain);
        });
    },
    createNotificationsDocOnUsers: function () {
      db.collection("users")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("users")
              .doc(doc.id)
              .collection("data")
              .doc("notifications")
              .set({
                chat: {},
              })
              .then(() => {
                console.log("created");
              });
          });
        });
    },
    test10: function () {
      let firebaseData;

      db.collection("app")
        .doc("settings")
        .get()
        .then((doc) => {
          firebaseData = doc.data().deliveryCutOff;

          Object.keys(firebaseData).forEach((key) => {
            console.log(parseInt(key));
          });
        });
    },
    initRequiredTranslationFields: function () {
      db.collection("app")
        .doc("translations")
        .set({
          activeLanguages: {
            en: "English",
          },
          languages: {
            da: "Danish",
            de: "German",
            en: "English",
            fr: "French",
            hr: "Croatian",
            it: "Italian",
            mn: "Mongolian",
            ne: "Nepali",
            nl: "Dutch",
            no: "Norwegian",
            sv: "Swedish",
            th: "Thai",
            ur: "Urdu",
            vi: "Vietnamese",
            zh: "Chinese",
          },
        });

      db.collection("app")
        .doc("translations")
        .collection("languages")
        .doc("fields")
        .set({
          strings: strings.strings,
        });

      db.collection("app")
        .doc("translations")
        .collection("languages")
        .doc("en")
        .set({
          published: true,
          strings: strings.strings,
        });

      db.collection("app")
        .doc("settings")
        .update({
          languages: {
            en: "English",
          },
        });
    },
    convertProductCategoriesWithTranslations: function () {
      db.collection("app")
        .doc("translations")
        .get()
        .then((doc) => {
          const languageKeys = Object.keys(doc.data().activeLanguages);

          db.collection("app")
            .doc("productSettings")
            .get()
            .then((doc) => {
              const data = doc.data();
              const categories = data.categories;

              let productKeys = {};

              let langKeys = {};
              for (const [key, value] of Object.entries(categories)) {
                console.log(key);
                console.log(value);

                productKeys[key] = "";
                langKeys[key] = value;
              }

              console.log(productKeys);

              let newData = {};
              languageKeys.forEach((key) => {
                if (key === "en") {
                  newData[key] = langKeys;
                } else {
                  newData[key] = productKeys;
                }
              });

              console.log(newData);

              db.collection("app").doc("productSettings").update({
                categoriesTranslated: newData,
              });
            });
        });
    },
    test9: function () {
      const notification = {
        title: "Your title",
        options: {
          body: "This is an example!",
        },
        events: {
          onerror: function () {
            console.log("Custom error event was called");
          },
          onclick: function () {
            console.log("Custom click event was called");
          },
          onclose: function () {
            console.log("Custom close event was called");
          },
          onshow: function () {
            console.log("Custom show event was called");
          },
        },
      };
      this.$notification.show(
        notification.title,
        notification.options,
        notification.events
      );
      // db.collection("companies")
      //   .get()
      //   .then((snap) => {
      //     snap.forEach((doc) => {
      //       if (doc.data().recommendedProducts === undefined) {
      //         console.log(doc.id);
      //       }
      //     });
      //   });
    },
    test8: function () {
      // db.collection("companies")
      //   .get()
      //   .then((snap) => {
      //     snap.forEach((doc) => {
      //       db.collection("companies")
      //         .doc(doc.id)
      //         .update({
      //           priceList: "standard",
      //         })
      //         .then(() => {
      //           console.log("done");
      //         });
      //     });
      //   });
    },
    test7: function () {
      const updatePromises = data.dtos.map((entry) => {
        return new Promise((resolve) => {
          // Company price list
          if (entry.companyId !== 0 && entry.r5 === 0) {
            console.log("company");
            resolve();
          } else if (entry.companyId === 0 && entry.r5 !== 0) {
            console.log("chain");
            resolve();
          } else if (entry.companyId === 0 && entry.r5 === 0) {
            console.log("standard");
            resolve();
          } else {
            console.log("unknown");
            resolve();
          }
        });
      });

      Promise.all(updatePromises).then(() => {
        console.log("alldone");
      });
    },
    initRequiredCompanyFields: function () {
      db.collection("companies")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("companies").doc(doc.id).update({
              recommendedProducts: {},
              recommendedShortlistProducts: {},
              testGroup: "none",
            });
          });
        });
    },
    initRecommendedProductsField: function () {
      db.collection("companies")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("companies").doc(doc.id).update({
              recommendedProducts: {},
              recommendedShortlistProducts: {},
              testGroup: "none",
            });
          });
        });
    },
    createAnalyticsForExistingUsers: function () {
      const year = this.$moment().format("YYYY");
      const weekNow = this.$moment().isoWeek();
      const weekNext = weekNow + 1;
      console.log(year);
      console.log(weekNow);
      console.log(weekNext);
      db.collection("users")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            const data = doc.data();
            db.collection("analytics").doc(doc.id).set({
              name: data.name,
              active: true,
              company: data.companyId,
              vismaId: data.vismaId,
            });

            db.collection("analytics")
              .doc(doc.id)
              .collection("logs")
              .doc(year.toString() + weekNow.toString())
              .set({});

            db.collection("analytics")
              .doc(doc.id)
              .collection("logs")
              .doc(year.toString() + weekNext.toString())
              .set({});
          });
        });
    },
    adminCreateCartDocOnUsers: function () {
      db.collection("users")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("users")
              .doc(doc.id)
              .collection("data")
              .doc("cart")
              .set({
                cart: {},
              })
              .then(() => {
                console.log("Updated user: " + doc.id);
              });
          });
        });
    },
    adminCreateLangFieldOnUsers: function () {
      db.collection("users")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("users")
              .doc(doc.id)
              .update({
                lang: "en",
              })
              .then(() => {
                console.log("Updated user: " + doc.id);
              });
          });
        });
    },
    test6: function () {
      db.collection("products")
        .get()
        .then((snap) => {
          console.log(snap.size);
          snap.forEach((doc) => {
            const data = doc.data();
            if (data.category === undefined) {
              console.log(doc.id);
            }
          });
        });
      // console.log(this.$moment().format("YYYY"));
      // db.collection("app")
      //   .doc("translations")
      //   .collection("languages")
      //   .doc("fields")
      //   .get()
      //   .then((doc) => {
      //     console.log(doc.data());
      //     let strings = doc.data().strings;
      //     let newLength = 0;
      //     let oldLength = 0;

      //     Object.keys(strings).forEach((key) => {
      //       newLength += Object.keys(strings[key]).length;
      //     });

      //     console.log(newLength);
      //   });

      // db.collection("users")
      //   .where("lang", "==", "en")
      //   .get()
      //   .then((snap) => {
      //     console.log(snap.size);
      //   });
    },
    test5: function () {
      console.log("????");

      let productList = [];
      db.collection("app")
        .doc("productList")
        .get()
        .then((doc) => {
          productList = doc.data().products;
          db.collection("app")
            .doc("cronHelper")
            .get()
            .then((doc) => {
              const updateList = doc.data().shortlists;

              if (Object.keys(updateList).length > 0) {
                let i = 1;

                for (const [id, action] of Object.entries(updateList)) {
                  setTimeout(() => {
                    if (action === "update" || action === "create") {
                      let products = [];
                      db.collection("shortlists")
                        .doc(id)
                        .get()
                        .then((doc) => {
                          const data = doc.data();

                          const exportData = {};

                          exportData.firebaseId = doc.id;
                          exportData.name = data.name;

                          Object.keys(data.products).forEach((firebaseId) => {
                            let prod = productList.find(
                              (product) => product.firebaseId === firebaseId
                            );

                            if (prod !== undefined) {
                              products.push(prod.vismaId);
                            }
                          });

                          if (data.type === "shared") {
                            exportData.type = "shared";
                            exportData.companies = data.companies.map(
                              (company) => {
                                return company.vismaId;
                              }
                            );
                          } else if (data.type === "company") {
                            exportData.company = data.companyId;
                            exportData.type = "company";
                          }

                          exportData.products = products;

                          db.collection("shortlistsExport")
                            .doc(id)
                            .set(exportData, { merge: false })
                            .then(() => {
                              console.log("Updated doc: " + id);
                            });
                        });
                    } else if (action === "delete") {
                      db.collection("shortlistsExport")
                        .doc(id)
                        .delete()
                        .then(() => {
                          console.log("Deleted doc: " + id);
                        });
                    }
                  }, i * 1000);

                  i++;
                }
              }

              console.log(updateList);
            });
        });
    },
    adminSyncExportableShortlistsManually: function () {
      this.axios
        .get(
          this.appSettings.cloudFunctions +
            "adminSyncExportableShortlistsManually"
        )
        .then((res) => {
          console.log(res);
        });
    },
    test4: function () {
      const dispatchDateString = "20220323";

      let appSettings;
      let companyData;
      const insertAt = (str, sub, pos) =>
        `${str.slice(0, pos)}${sub}${str.slice(pos)}`;

      console.log(insertAt(insertAt(dispatchDateString, "-", 4), "-", 7));

      return;
      const p1 = new Promise((resolve) => {
        return db
          .collection("app")
          .doc("settings")
          .get()
          .then((res) => {
            appSettings = res.data();
            resolve();
          });
      });

      const p2 = new Promise((resolve) => {
        return db
          .collection("companies")
          .doc("Ux5KCsYuveAWQV4vwWQD")
          .get()
          .then((res) => {
            companyData = res.data();
            resolve();
          });
      });

      Promise.all([p1, p2]).then(() => {
        const cutOffHour = parseInt(appSettings.deliveryStandardTime.hours);
        let daysAfter = 0;

        if (companyData.deliveryRoute !== undefined) {
          if (
            appSettings.deliveryCutOff[companyData.deliveryRoute.toString()] !==
            undefined
          ) {
            const routeData =
              appSettings.deliveryCutOff[companyData.deliveryRoute.toString()];

            const newCutOffHour = cutOffHour - routeData.hours;

            if (newCutOffHour < 0) {
              daysAfter += Math.floor(newCutOffHour / 24) * -1;
            }
          }
        }

        for (let i = 1; i <= daysAfter; i++) {
          let testDate = new Date(dispatchDate);
          testDate.setDate(testDate.getDate() + i);
          const momentDate = this.$moment(testDate).format("YYYY-MM-DD");

          let checkDay = testDate.getDay();

          // In JS 0 is sunday, in Dart it's monday
          if (checkDay === 0) {
            checkDay = 7;
          }

          if (
            appSettings.unavailableDates.includes(momentDate) ||
            appSettings.deliveryBlockedDays.includes(checkDay)
          ) {
            daysAfter++;
          }
        }

        let returnDate = new Date(dispatchDate);
        returnDate.setDate(returnDate.getDate() + daysAfter);

        console.log(this.$moment(returnDate).format("YYYY-MM-DD"));
      });
    },
    createExportableShortlists: function () {
      db.collection("shortlists")
        .get()
        .then((snap) => {
          let returnArr = [];

          snap.forEach((doc) => {
            let data = doc.data();

            let returnData = {};
            let products = [];

            returnData.firebaseId = doc.id;
            returnData.name = data.name;

            if (data.type === "shared") {
              returnData.type = "shared";

              Object.keys(data.products).forEach((firebaseId) => {
                const prod = this.getProductByFirebaseId(firebaseId);
                if (prod.name !== "Deleted product") {
                  products.push(prod.vismaId);
                }
              });

              returnData.companies = data.companies.map((company) => {
                return company.vismaId;
              });
            } else if (data.type === "company") {
              returnData.company = data.companyId;
              returnData.type = "company";

              Object.keys(data.products).forEach((firebaseId) => {
                const prod = this.getProductByFirebaseId(firebaseId);
                if (prod.name !== "Deleted product") {
                  products.push(prod.vismaId);
                }
              });
            }

            returnData.products = products;
            returnArr.push(returnData);
          });

          console.log(returnArr);
          var i = 0;
          for (const data of returnArr) {
            setTimeout(() => {
              db.collection("shortlistsExport")
                .doc(data.firebaseId)
                .set(data, { merge: false })
                .then(() => {
                  console.log("created");
                });
            }, i * 1000);

            i++;
          }

          // returnArr.forEach((data) => {

          //   db.collection("shortlistsExport")
          //     .doc(data.firebaseId)
          //     .set(data, { merge: false })
          //     .then(() => {
          //       console.log("created");
          //     });

          // });
        });
    },
    test3: function () {
      db.collection("shortlistsExport")
        .get()
        .then((snap) => {
          console.log(snap.size);
        });
    },
    findUsersWhoChangedEmail: function () {
      this.axios
        .get(this.appSettings.cloudFunctions + "adminCheckAuthUsers")
        .then((res) => {
          console.log(res);
        });
    },
    test2: function () {
      // console.log(this.sharedShortlists);
      // let shortlists = this.sharedShortlists.map((list) => {
      //   return {
      //     name: list.name,
      //     products: list.products,
      //     type: list.type,
      //     id: list.id,
      //   };
      // });
      // let listsWithSalmon = shortlists.filter(
      //   (list) => list.products["8XbJsSfers6gkwJNvxWJ"] !== undefined
      // );
      // console.log(listsWithSalmon);
      // let companiesWithNoPriceLists = listsWithSalmon
      //   .filter(
      //     (list) =>
      //       this.getPriceListById(list.companyId.toString()) === undefined
      //   )
      //   .map((list) => {
      //     return (
      //       list.companyId +
      //       " - " +
      //       this.getCompanyNameByVismaId(list.companyId)
      //     );
      //   });
      // let uniqueCompaniesWithNoPriceLists = [
      //   ...new Set(companiesWithNoPriceLists),
      // ];
      // let companiesWithPriceLists = listsWithSalmon.filter(
      //   (list) => this.getPriceListById(list.companyId.toString()) !== undefined
      // );
      // let no56xxxOnList = companiesWithPriceLists
      //   .filter((list) => {
      //     let priceList = this.getPriceListById(list.companyId.toString());
      //     let products = [];
      //     Object.keys(priceList.products).forEach((productId) => {
      //       if (productId.toString().startsWith("56")) {
      //         products.push(productId);
      //       }
      //     });
      //     if (products.length === 0) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   })
      //   .map((list) => {
      //     return (
      //       list.companyId +
      //       " - " +
      //       this.getCompanyNameByVismaId(list.companyId)
      //     );
      //   });
      // let ready2Replace = companiesWithPriceLists
      //   .filter((list) => {
      //     let priceList = this.getPriceListById(list.companyId.toString());
      //     let products = [];
      //     Object.keys(priceList.products).forEach((productId) => {
      //       if (productId.toString().startsWith("56")) {
      //         products.push(productId);
      //       }
      //     });
      //     if (products.length === 0) {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   })
      //   .map((list) => {
      //     let priceList = this.getPriceListById(list.companyId.toString());
      //     let products = [];
      //     Object.keys(priceList.products).forEach((productId) => {
      //       if (productId.toString().startsWith("56")) {
      //         products.push(productId);
      //       }
      //     });
      //     return {
      //       productId: this.getProductByVismaId(products[0]).firebaseId,
      //       listId: list.id,
      //     };
      //   });
      // console.log(ready2Replace);
      // ready2Replace.forEach((replaceList) => {
      //   console.log(replaceList);
      // db.collection("shortlists")
      //   .doc(replaceList.listId)
      //   .update({
      //     ["products.8XbJsSfers6gkwJNvxWJ"]:
      //       firebase.firestore.FieldValue.delete(),
      //     ["products." + replaceList.productId]: true,
      //   })
      //   .then(() => {
      //     console.log("Updated: " + replaceList.listId);
      //   });
      // });
      // console.log(listsWithSalmon.length);
      // console.log(companiesWithNoPriceLists.length);
      // console.log(companiesWithPriceLists.length);
      // console.log(no56xxxOnList.length);
      // console.log(have56xxxOnList.length);
      // console.log(shortlists);
      // console.log(listsWithSalmon);
      // console.log(companiesWithNoPriceLists);
      // console.log(uniqueCompaniesWithNoPriceLists);
      // console.log(no56xxxOnList);
      // console.log(companiesWithPriceLists);
      // console.log(test);
      // no56xxxOnList.forEach((item) => {
      //   console.log(item);
      // });
    },
    // test: function () {
    //   let start = new Date("2022-01-01");
    //   db.collection("orders")
    //     .where("createdAt", ">", start)
    //     .get()
    //     .then((snapshot) => {
    //       let returnData = [];

    //       snapshot.forEach((doc) => {
    //         const data = doc.data();
    //         const vismaId = data.vismaId;
    //         const createdAt = data.createdAt.toDate();
    //         const deliveryDate = data.deliveryDate.toDate();
    //         let user = data.user;

    //         if (user !== "vismaSync") {
    //           user = this.getUserEmailById(user);
    //         }

    //         returnData.push({
    //           vismaId: vismaId,
    //           createdBy: user,
    //           createdAt: createdAt,
    //           deliveryDate: deliveryDate,
    //         });
    //       });

    //       console.log(JSON.stringify(returnData));
    //     });
    // },
    adminSetUsersLastSignInManually: async function () {
      this.axios
        .get(
          this.appSettings.cloudFunctions + "adminSetUsersLastSignInManually"
        )
        .then((res) => {
          console.log(res);
        });
    },
    moveTaxonomies: async () => {
      const settingsQuery = db.collection("app").doc("settings");
      const newLocation = db.collection("app").doc("taxonomies");
      const settingsDoc = await settingsQuery.get();
      const settingsData = settingsDoc.data();
      let categories = settingsData.companyCategories;
      let priceGroup1 = settingsData.companyPriceGroups;
      let priceGroup2 = settingsData.companyPriceGroups2;
      let priceGroup3 = settingsData.companyPriceGroups3;
      priceGroup1.name = "priceGroup1";
      priceGroup2.name = "priceGroup2";
      priceGroup3.name = "priceGroup3";
      try {
        await newLocation.set({
          categories: categories,
          priceGroup1: priceGroup1,
          priceGroup2: priceGroup2,
          priceGroup3: priceGroup3,
        });
      } catch (e) {
        console.log(e);
        return;
      }

      console.log("Categories moved");
    },
    checkForAllowCredits: function () {
      db.collection("companies")
        .where("allowCredit", "==", false)
        .get()
        .then((snapshot) => {
          if (snapshot.size == 0) {
            console.log("All users are allowed to make purchases");
          } else {
            snapshot.docs.forEach((doc) => {
              console.log(
                `No credit company: ${doc.data().name} - ${doc.data().vismaId}`
              );
            });
          }
        });
    },
    checkForDeletedPostsOnCompanies: function () {
      console.log("here");
      // db.collection("companies")
      //   .doc("OJkHXGDWDZu9qOkTJFI0")
      //   .get()
      //   .then((doc) => {
      //     doc.data().posts.forEach((post) => {
      //       db.collection("posts")
      //         .doc(post)
      //         .get()
      //         .then((postDoc) => {
      //           if (postDoc.data().status === "draft") {
      //             console.log(postDoc.id);
      //           }
      //           // if (!postDoc.exists) {
      //           //   console.log("Post does not exist");
      //           // } else {
      //           //   // console.log("Post exists");
      //           //   const data = postDoc.data();

      //           //   console.log(data.mainTitle);
      //           // }
      //         });
      //     });
      //   });
      db.collection("posts")
        .where("status", "==", "draft")
        .get()
        .then((postsSnapshot) => {
          let checkFor = [];
          postsSnapshot.forEach((post) => {
            checkFor.push(post.id);
          });

          console.log(checkFor);

          db.collection("companies")
            .get()
            .then((companiesSnapshot) => {
              companiesSnapshot.forEach((doc) => {
                // console.log(doc.data().posts);

                if (doc.data().posts !== undefined) {
                  doc.data().posts.forEach((post) => {
                    if (checkFor.includes(post)) {
                      db.collection("companies");
                      // .doc(doc.id)
                      // .update({
                      //   posts:
                      //     firebase.firestore.FieldValue.arrayRemove(post),
                      // })
                      // .then(() => {
                      //   console.log("Deleted " + post + " from " + doc.id);
                      // });
                      console.log(doc.id + "has drafted post: " + post);
                    }
                  });
                }
              });
            });
        });
    },
    createPriceLists: function () {
      this.axios
        .post(this.appSettings.cloudFunctions + "adminCreatePriceLists", {
          env: this.$cookies.get("version"),
          brand: this.appBrand,
        })
        .then((res) => {
          console.log(res);
        });
    },
    createPriceListsNew: function () {
      this.axios
        .post(this.appSettings.cloudFunctions + "adminCreatePriceListsNew", {
          env: this.$cookies.get("version"),
          brand: this.appBrand,
        })
        .then((res) => {
          console.log(res);
        });
    },
    syncPriceListsNew: function () {
      this.axios
        .post(this.appSettings.cloudFunctions + "adminSyncPriceListsManually", {
          env: this.$cookies.get("version"),
          brand: this.appBrand,
        })
        .then((res) => {
          console.log(res);
        });
    },
    setBaseDiscounts: function () {
      this.axios
        .post(
          this.appSettings.cloudFunctions + "adminSetBaseDiscountsManually",
          {
            env: this.$cookies.get("version"),
            brand: this.appBrand,
          }
        )
        .then((res) => {
          console.log(res);
        });
    },
    setInitialPriceListOnExistingCompanies: function () {
      return db
        .collection("companies")
        .get()
        .then((companiesSnapshot) => {
          companiesSnapshot.forEach((doc) => {
            const companyData = doc.data();
            const companyId = doc.id;

            // console.log( companyId );
            return db
              .collection("priceLists")
              .doc(companyData.vismaId.toString())
              .get()
              .then((companyPriceListDoc) => {
                if (!companyPriceListDoc.exists) {
                  return db
                    .collection("priceLists")
                    .doc(companyData.companyPriceGroup.toString())
                    .get()
                    .then((groupPriceListDoc) => {
                      if (!groupPriceListDoc.exists) {
                        // Neither company or group pricelist exists for this customer
                        // Fallback to the already set 'standard'
                        return db
                          .collection("companies")
                          .doc(companyId)
                          .update({
                            priceList: "standard",
                          })
                          .then(() => {
                            console.log(
                              companyData.name + " has price list " + "standard"
                            );
                          });
                      } else {
                        // Use company group price list for customer
                        return db
                          .collection("companies")
                          .doc(companyId)
                          .update({
                            priceList: companyData.companyPriceGroup,
                          })
                          .then(() => {
                            console.log(
                              companyData.name +
                                " has price list " +
                                companyData.companyPriceGroup
                            );
                          });
                      }
                    });
                } else {
                  // Use company specific price list for customer
                  return db
                    .collection("companies")
                    .doc(companyId)
                    .update({
                      priceList: companyData.vismaId,
                    })
                    .then(() => {
                      console.log(
                        companyData.name +
                          " has price list " +
                          companyData.vismaId
                      );
                    });
                }
              });
          });
        });
    },
    migrateCompanyShortlist: function () {
      db.collection("companies")
        .get()
        .then((companiesSnapshot) => {
          companiesSnapshot.forEach((doc) => {
            const data = doc.data();
            const companyId = doc.id;

            // Check if user has shortlist.
            if (Object.keys(data.shortlist).length > 0) {
              console.log(data.shortlist);

              db.collection("shortlists")
                .add({
                  name: "Your shortlist",
                  products: data.shortlist,
                  companyId: data.vismaId,
                  type: "company",
                })
                .then((doc) => {
                  db.collection("companies")
                    .doc(companyId)
                    .update({
                      ["shortlists." + doc.id]: {
                        name: "Your shortlist",
                        type: "company",
                      },
                    })
                    .then(() => {
                      console.log("Added shortlist for company " + data.name);
                    });
                });
            } else {
              console.log(data.name + " has no shortlist currently");
            }
          });
        });
    },
    addShortlistsFieldToCompanies: function () {
      db.collection("companies")
        .get()
        .then((companiesSnapshot) => {
          companiesSnapshot.forEach((doc) => {
            if (doc.data().shortlists === undefined) {
              db.collection("companies")
                .doc(doc.id)
                .update({
                  shortlists: {},
                })
                .then(() => {
                  console.log(
                    'Added "shortlists" to company: ' + doc.data().name
                  );
                });
            }
          });
        });
    },
    addProductsPriceFieldToCompanies: function () {
      db.collection("companies")
        .get()
        .then((companiesSnapshot) => {
          companiesSnapshot.forEach((doc) => {
            if (doc.data().productsPrice === undefined) {
              db.collection("companies")
                .doc(doc.id)
                .update({
                  productsPrice: 1,
                })
                .then(() => {
                  console.log(
                    'Added "productsPrice" to company: ' + doc.data().name
                  );
                });
            }
          });
        });
    },
    addProductsStockFieldToCompanies: function () {
      db.collection("companies")
        .get()
        .then((companiesSnapshot) => {
          companiesSnapshot.forEach((doc) => {
            if (doc.data().productsStock === undefined) {
              db.collection("companies")
                .doc(doc.id)
                .update({
                  productsStock: 1,
                })
                .then(() => {
                  console.log(
                    'Added "productsStock" to company: ' + doc.data().name
                  );
                });
            }
          });
        });
    },
    addPriceListFieldToCompanies: function () {
      db.collection("companies")
        .get()
        .then((companiesSnapshot) => {
          companiesSnapshot.forEach((doc) => {
            if (doc.data().priceList === undefined) {
              db.collection("companies")
                .doc(doc.id)
                .update({
                  priceList: "standard",
                })
                .then(() => {
                  console.log(
                    'Added "priceList" to company: ' + doc.data().name
                  );
                });
            }
          });
        });
    },
    adminDev: function () {
      console.log(this.appSettings.cloudFunctions + "adminDev");
      this.axios.get(this.appSettings.cloudFunctions + "adminDev").then(() => {
        console.log("done");
      });
    },
    checkUserChatrooms: function () {
      // Checks if users have chat personal chat rooms.
      db.collection("users")
        .get()
        .then((usersSnapshot) => {
          usersSnapshot.forEach((doc) => {
            db.collection("chatRooms")
              .where("identifier", "==", doc.id)
              .get()
              .then((chatRoomsSnapshot) => {
                console.log(chatRoomsSnapshot.size);
              });
          });
        });
    },
    checkCompanyChatrooms: function () {
      // Checks if users have chat personal chat rooms.
      db.collection("companies")
        .get()
        .then((companiesSnapshot) => {
          companiesSnapshot.forEach((doc) => {
            db.collection("chatRooms")
              .where("identifier", "==", doc.data().vismaId.toString())
              .get()
              .then((chatRoomsSnapshot) => {
                console.log(chatRoomsSnapshot.size);
              });
          });
        });
    },
    addNameAndAccountOwnerToCompanyChatroom: function () {
      db.collection("chatRooms")
        .where("type", "==", "company")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let chatRoomId = doc.id;
            let companyVismaId = parseInt(doc.data().identifier);
            let currentAccountOwner = doc.data().accountOwnerVismaId;

            if (currentAccountOwner == undefined) {
              db.collection("companies")
                .where("vismaId", "==", companyVismaId)
                .get()
                .then((companySnapshot) => {
                  if (companySnapshot.size > 0) {
                    let companyData = companySnapshot.docs[0].data();

                    db.collection("chatRooms")
                      .doc(chatRoomId)
                      .update({
                        name: companyData.name,
                        accountOwnerVismaId: companyData.accountOwnerVismaId,
                      })
                      .then(() => {
                        console.log("added company info");
                      });
                  }
                });
            }
          });
        });
    },
    addAccountOwnerToPersonalChatRooms: function () {
      db.collection("chatRooms")
        .where("type", "==", "personal")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let chatRoomId = doc.id;
            let userId = doc.data().identifier;
            let currentAccountOwner = doc.data().accountOwnerVismaId;

            if (currentAccountOwner == undefined) {
              db.collection("users")
                .doc(userId)
                .get()
                .then((userDoc) => {
                  let userData = userDoc.data();
                  db.collection("companies")
                    .where("vismaId", "==", userData.companyId)
                    .get()
                    .then((qSnapshot) => {
                      if (qSnapshot.size > 0) {
                        let companyData = qSnapshot.docs[0].data();

                        db.collection("chatRooms")
                          .doc(chatRoomId)
                          .update({
                            accountOwnerVismaId:
                              companyData.accountOwnerVismaId,
                          })
                          .then(() => {
                            console.log(
                              "added accountOwner: " +
                                companyData.accountOwnerVismaId
                            );
                          });
                      }
                    });
                });
            }
          });
        });
    },
    addNamesToPersonalChatRooms: function () {
      db.collection("chatRooms")
        .where("type", "==", "personal")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let chatRoomId = doc.id;
            let userId = doc.data().identifier;

            let currentName = doc.data().name;
            if (currentName == undefined) {
              db.collection("users")
                .doc(userId)
                .get()
                .then((userDoc) => {
                  let data = userDoc.data();
                  db.collection("chatRooms")
                    .doc(chatRoomId)
                    .update({
                      name: data.name,
                    })
                    .then(() => {
                      console.log("added name: " + data.name);
                    });
                });
            }
          });
        });
    },
    clearOldUserChatRooms: function () {
      db.collection("chatRooms")
        .where("type", "==", "personal")
        .get()
        .then((querySnapshot) => {
          console.log(querySnapshot.size);

          querySnapshot.forEach((doc) => {
            if (
              this.userList.find((user) => user.id === doc.data().identifier)
            ) {
              console.log("found chat room");
            } else {
              db.collection("chatRooms")
                .doc(doc.id)
                .delete()
                .then(() => {
                  console.log("deleted chat room");
                });
            }
          });
        });
    },
    clearOldCompanyChatRooms: function () {
      db.collection("chatRooms")
        .where("type", "==", "company")
        .get()
        .then((querySnapshot) => {
          console.log(querySnapshot.size);
          querySnapshot.forEach((doc) => {
            if (this.getCompanyByVismaId(parseInt(doc.data().identifier))) {
              // console.log("found chat room");
            } else {
              console.log("no found");
              db.collection("chatRooms")
                .doc(doc.id)
                .delete()
                .then(() => {
                  console.log("deleted chat room");
                });
            }
          });
        });
    },
    testCompanyFields: function () {
      db.collection("companies")
        .get()
        .then((querySnapshot) => {
          let valid = 0;

          querySnapshot.forEach((doc) => {
            const data = doc.data();
            let missingFields = [];

            if (
              data.accountOwnerVismaId === undefined ||
              typeof data.accountOwnerVismaId !== "number"
            ) {
              missingFields.push("accountOwnerVismaId");
            }

            if (
              data.address1 === undefined ||
              typeof data.address1 !== "string"
            ) {
              missingFields.push("address1");
            }

            if (
              data.address2 === undefined ||
              typeof data.address2 !== "string"
            ) {
              missingFields.push("address2");
            }

            if (
              data.allowCredit === undefined ||
              typeof data.allowCredit !== "boolean"
            ) {
              missingFields.push("allowCredit");
            }

            if (data.area === undefined || typeof data.area !== "string") {
              missingFields.push("area");
            }

            if (
              data.companyCategory === undefined ||
              typeof data.companyCategory !== "number"
            ) {
              missingFields.push("companyCategory");
            }

            if (
              data.companyPriceGroup === undefined ||
              typeof data.companyPriceGroup !== "number"
            ) {
              missingFields.push("companyPriceGroup");
            }

            if (
              data.companyPriceGroup2 === undefined ||
              typeof data.companyPriceGroup2 !== "number"
            ) {
              missingFields.push("companyPriceGroup2");
            }

            if (
              data.companyPriceGroup3 === undefined ||
              typeof data.companyPriceGroup3 !== "number"
            ) {
              missingFields.push("companyPriceGroup3");
            }

            if (
              data.contactEmails === undefined ||
              typeof data.contactEmails !== "object"
            ) {
              missingFields.push("contactEmails");
            }

            if (
              data.contactPhone === undefined ||
              typeof data.contactPhone !== "string"
            ) {
              missingFields.push("contactPhone");
            }

            if (data.cvr === undefined || typeof data.cvr !== "string") {
              missingFields.push("cvr");
            }

            if (
              data.deliveryRoute === undefined ||
              typeof data.deliveryRoute !== "number"
            ) {
              missingFields.push("deliveryRoute");
            }

            if (data.name === undefined || typeof data.name !== "string") {
              missingFields.push("name");
            }

            if (
              data.postCode === undefined ||
              typeof data.postCode !== "string"
            ) {
              missingFields.push("postCode");
            }

            if (data.posts === undefined || typeof data.posts !== "object") {
              missingFields.push("posts");
            }

            if (
              data.priceList === undefined ||
              (typeof data.priceList !== "string" &&
                typeof data.priceList !== "number")
            ) {
              missingFields.push("priceList");
            }

            if (
              data.productsPrice === undefined ||
              typeof data.productsPrice !== "number"
            ) {
              missingFields.push("productsPrice");
            }

            if (
              data.productsStock === undefined ||
              typeof data.productsStock !== "number"
            ) {
              missingFields.push("productsStock");
            }

            if (
              data.shortlists === undefined ||
              typeof data.shortlists !== "object"
            ) {
              missingFields.push("shortlists");
            }

            if (
              data.vismaId === undefined ||
              typeof data.vismaId !== "number"
            ) {
              missingFields.push("vismaId");
            }

            if (missingFields.length > 0) {
              console.log(
                doc.id + " missing fields: " + missingFields.join(", ")
              );
            } else {
              valid++;
            }
          });

          console.log("Valid: " + valid);
        });
    },
    testUserFields: function () {
      db.collection("users")
        .get()
        .then((querySnapshot) => {
          let valid = 0;

          querySnapshot.forEach((doc) => {
            const data = doc.data();
            let missingFields = [];

            if (data.cart === undefined || typeof data.cart !== "object") {
              missingFields.push("cart");
            }

            if (
              data.chatNotifications === undefined ||
              typeof data.chatNotifications !== "object"
            ) {
              missingFields.push("chatNotifications");
            }

            if (
              data.companyId === undefined ||
              typeof data.companyId !== "number"
            ) {
              missingFields.push("companyId");
            }

            if (data.email === undefined || typeof data.email !== "string") {
              missingFields.push("email");
            }

            if (data.name === undefined || typeof data.name !== "string") {
              missingFields.push("name");
            }

            if (data.phone === undefined || typeof data.phone !== "string") {
              missingFields.push("phone");
            }

            if (
              data.pushToken === undefined ||
              (typeof data.pushToken !== "string" && data.pushToken !== null)
            ) {
              missingFields.push("pushToken");
              // db.collection('users').doc(doc.id).update({
              //   'pushToken': null
              // }).then( () => {
              //   console.log('updated pushToken on ' + doc.id);
              // })
            }

            if (
              data.role === undefined ||
              (typeof data.role !== "string" &&
                ["user", "superUser", "admin", "superAdmin"].includes(
                  data.role
                ))
            ) {
              missingFields.push("role");
            }

            if (data.title === undefined || typeof data.title !== "string") {
              missingFields.push("title");
              // db.collection('users').doc(doc.id).update({
              //   'title': ''
              // }).then( () => {
              //   console.log('updated title on ' + doc.id);
              // })
            }

            if (data.uid === undefined || typeof data.uid !== "string") {
              missingFields.push("uid");
            }

            if (
              data.vismaId === undefined ||
              typeof data.vismaId !== "number"
            ) {
              missingFields.push("vismaId");
            }

            if (missingFields.length > 0) {
              console.log(
                doc.id + " missing fields: " + missingFields.join(", ")
              );
            } else {
              valid++;
            }
          });

          console.log("Valid: " + valid);
        });
    },
    changeBrand: function (to) {
      this.$cookies.set("brand", to);
      location.reload();
    },
    clearUserCarts: function () {
      db.collection("users")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(doc.id);

            db.collection("users").doc(doc.id).update({
              cart: {},
            });
          });
        });
    },
    clearUserChatNotifications: function () {
      db.collection("users")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(doc.id);

            db.collection("users").doc(doc.id).update({
              chatNotifications: {},
            });
          });
        });
    },
    generateMissingChatRooms: async function () {
      // Generates missing chat rooms for companies and users.
      // const companiesSnap = await db.collection("companies").get();
      // const toCreate = {};
      // await Promise.all(
      //   companiesSnap.docs.map(async (companyDoc) => {
      //     const companyData = companyDoc.data();
      //     const chatRoomsSnap = await db
      //       .collection("chatRooms")
      //       .where("identifier", "==", companyData.vismaId.toString())
      //       .get();
      //     if (chatRoomsSnap.size === 0) {
      //       toCreate[companyDoc.id] = {
      //         type: "company",
      //         identifier: companyData.vismaId.toString(),
      //         name: companyData.name,
      //         accountOwnerVismaId: companyData.accountOwnerVismaId,
      //       };
      //     }
      //   })
      // );
      // console.log(toCreate);
      // if (Object.keys(toCreate).length > 0) {
      //   for (const [key, value] of Object.entries(toCreate)) {
      //     await db.collection("chatRooms").doc(key).set(value);
      //     console.log("Created chatRoom for company: " + value.name);
      //   }
      // }
      // const usersSnap = await db.collection("users").get();
      // const toCreate = [];
      // await Promise.all(
      //   usersSnap.docs.map(async (userDoc) => {
      //     const userData = userDoc.data();
      //     const chatRoomsSnap = await db
      //       .collection("chatRooms")
      //       .where("identifier", "==", userDoc.id)
      //       .get();
      //     if (chatRoomsSnap.size === 0) {
      //       const companySnap = await db
      //         .collection("companies")
      //         .where("vismaId", "==", userData.companyId)
      //         .get();
      //       if (companySnap.size > 0) {
      //         console.log("no company");
      //         const accountOwner =
      //           companySnap.docs[0].data().accountOwnerVismaId;
      //         toCreate.push({
      //           type: "personal",
      //           identifier: userDoc.id,
      //           name: userData.name,
      //           accountOwnerVismaId: accountOwner,
      //         });
      //       }
      //       // toCreate.push({
      //       //   type: "personal",
      //       //   identifier: userDoc.id,
      //       //   name: userData.name,
      //       //   accountOwnerVismaId: userData.companyId,
      //       // });
      //     }
      //   })
      // );
      // for (const create of toCreate) {
      //   await db.collection("chatRooms").add(create);
      //   console.log("Created chatRoom for user: " + create.name);
      // }
      // console.log(toCreate);
      // db.collection("companies")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       db.collection("chatRooms")
      //         .where("identifier", "==", doc.data().vismaId.toString())
      //         .get()
      //         .then((querySnapshot) => {
      //           if (querySnapshot.size === 0) {
      //             console.log("missing for company" + doc.id);
      //             // db.collection("chatRooms")
      //             //   .add({
      //             //     type: "company",
      //             //     identifier: doc.data().vismaId.toString(),
      //             //   })
      //             //   .then(() => {
      //             //     console.log(
      //             //       "Created chatRoom for company: " + doc.data().name
      //             //     );
      //             //   });
      //           }
      //         });
      //     });
      //   });
      // db.collection("users")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       db.collection("chatRooms")
      //         .where("identifier", "==", doc.id)
      //         .get()
      //         .then((querySnapshot) => {
      //           if (querySnapshot.size === 0) {
      //             console.log("missing for user" + doc.id);
      //             // db.collection("chatRooms")
      //             //   .add({
      //             //     type: "personal",
      //             //     identifier: doc.id,
      //             //   })
      //             //   .then(() => {
      //             //     console.log(
      //             //       "Created chatRoom for user: " + doc.data().name
      //             //     );
      //             //   });
      //           }
      //         });
      //     });
      //   });
    },
  },
};
</script>
